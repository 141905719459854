import React, { createContext, useState, useEffect } from "react";
import { fetchProducts } from "../apis/products";
import { fetchCategories } from "../apis/categories";
import { fetchDomain } from "../helper";
import { domainList } from "../constant";
import { fetchDomainInfo } from "../apis/domains";

const ContentContext = createContext();

const ContentProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [storeName, setStoreName] = React.useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [domainInfo, setDomainInfo] = React.useState("");
  const [domianFetchErr, setDomianFetchErr] = useState("");
  const [domainFetchStatus, setDomainFetchStatus] = useState("");

  const domain = fetchDomain();
  const FetchDomain = async () => {
    try {

    } catch (e) {
      
      setDomianFetchErr(e?.response?.data?.message || e.message);
      setDomainFetchStatus(e?.response?.status || "");
    }
  };
  const fetch = async () => {
    try {
      if (!domainList.includes(domain)) {
        const domainInfo = await fetchDomainInfo(domain);
        const products = await fetchProducts(domain);
        const categories = await fetchCategories(domain);
        setDomainInfo(domainInfo.data);
        setStoreName(domainInfo.data?.storeName);
        setProducts(products);
        setCategories(categories);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetch();
    FetchDomain();
  }, []);

  const values = {
    products,
    setProducts,
    categories,
    setCategories,
    storeName,
    setStoreName,
    domainInfo,
    setDomainInfo,
    isModalOpen,
    setIsModalOpen,
    selectedItems,
    setselectedItems,
    domianFetchErr,
    domainFetchStatus,
  };

  return (
    <ContentContext.Provider value={{ ...values }}>
      {children}
    </ContentContext.Provider>
  );
};

export { ContentContext, ContentProvider };
