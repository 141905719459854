import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { motion } from "framer-motion";

const AnnouncementBar = ({ note, email, mobile }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1145) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window?.removeEventListener("resize", handleResize);
  }, []);

  return (
    <motion.div>
      <div
        className={`hdt-top-bar hdt-s-gradient hdt-top-bar--bottom-border ${
          isSmallScreen && !note ? "hidden-bar" : ""
        }`}
      >
        <div className="hdt-container-full hdt-px-15 lg:hdt-px-40">
          <div className="hdt-top-bar__wrap hdt-row-grid hdt-grid-cols-1 md:hdt-grid-cols-1 lg:hdt-grid-cols-3 hdt-align-center">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 2, ease: "easeOut" }}
            >
              <div className="px-5 hdt-top-bar__item  hdt-top-bar__custom-liquid hdt-hidden md:hdt-hidden lg:hdt-block">
                <Link
                  to={`tel:${mobile}`}
                  style={{ marginInlineEnd: "2.9rem" }}
                >
                  {mobile}
                </Link>
              </div>
            </motion.div>

            <div className="hdt-top-bar__item hdt-top-bar__text hdt-text-center">
              <div
                autoheight=""
                className="hdt-top-bar-text__slider div lg:hdt-slides-1 hdt-relative hdt-oh"
              >
                <div className="div__viewport is-draggable">
                  <div className="div__container announcement-middle">
                    <motion.div
                      initial={{ y: -50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                      <div className="div__slide hdt-top-bar-text__item hdt-rte is-selected is-in-view">
                        {note}
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
            >
              <div className="px-5 text-end hdt-top-bar__item hdt-top-bar__custom-liquid hdt-hidden md:hdt-hidden lg:hdt-block">
                <Link to={`mailto:${email}`}>{email}</Link>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AnnouncementBar;
