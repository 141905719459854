import React, { useContext, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { createSlug } from "../../../../../helper";
import Placeholder from "../../../../../asesst/placeholder.jpeg";
import { ContentContext } from "../../../../../store";
import EmptyCart from "../../../Screens/Cart/Component/EmptyCart";
import { useMemo } from "react";
import { useEffect } from "react";

const ProductTableModalContent = ({ initialProducts }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { setIsModalOpen, setselectedItems, domainInfo } =
    useContext(ContentContext);
  const domain = domainInfo?.domain;
  const [products, setProducts] = useState();

  const nextChecout = () => {
    updateLocalStorage(products);
    navigate("/checkout");
    setIsModalOpen(false);
  };

  const handleViewCart = () => {
    updateLocalStorage(products);
    navigate("/cart");
    setIsModalOpen(false);
  };
  

  useEffect(() => {
    const checkQty = initialProducts?.map((product) => ({
      ...product,
      selectedQty: product.selectedQty ?? 1, // Initialize selectedQty to 1 if not set
    }));
    setProducts(checkQty);
  }, []);
  const updateLocalStorage = (updatedProducts) => {
    localStorage.setItem(`${domain}-carts`, JSON.stringify(updatedProducts));
  };
  const handleIncreaseQty = (index) => {
    const updatedProducts = [...products];
    updatedProducts[index].selectedQty += 1;
    setProducts(updatedProducts);
    setselectedItems(updatedProducts);
    updateLocalStorage(updatedProducts);
  };

  const handleDecreaseQty = (index) => {
    const updatedProducts = [...products];
    if (updatedProducts[index].selectedQty > 1) {
      updatedProducts[index].selectedQty -= 1;
    } else {
      updatedProducts.splice(index, 1);
    }
    setProducts(updatedProducts);
    setselectedItems(updatedProducts);
    updateLocalStorage(updatedProducts);
  };

  const subtotal = useMemo(() => {
    return products?.reduce((acc, product) => {
      return acc + Number(product.retailPrice) * product.selectedQty;
    }, 0);
  }, [products]);

  return (
    <>
      <h3 className="p-2 py-3 fw-bold fs-3">Shopping cart</h3>

      <div className="cart-item-container">
        {products?.length === 0 && (
          <h3 className="my-5">
            <EmptyCart />
          </h3>
        )}
        {products?.map((item, index) => (
          <div
            key={index}
            className="table_img_product border-bottom cart-item "
          >
            <div>
              <Link
                to={`/product/${createSlug(item?.itemname)}`}
                // className="hdt-page-cart__img hdt-block hdt-relative hdt-oh"
                style={{ "--aspect-ratioapt": 1.0 }}
              >
                <img
                  src={item?.image ?? Placeholder}
                  alt={item?.title}
                  width="60px"
                  height="60px"
                  loading="lazy"
                  sizes="(max-width: 767px) 80px, 160px"
                />
              </Link>
            </div>
            <div className="cart-item-right">
              <div className="product-info">
                <Link
                  to={`/product/${createSlug(item?.itemname)}`}
                  style={{ "--aspect-ratioapt": 1.0 }}
                >
                  <h5 className="product_details">
                    {item?.itemname} -{" "}
                    {item?.selectedVariant && (
                      <span className="product-info">
                        {item?.selectedVariant}
                      </span>
                    )}
                  </h5>
                </Link>
                <div>
                  Rs:{" "}
                  {`${item?.retailPrice ? Number(item?.retailPrice) : "00"}`}
                </div>
              </div>
              <div className="d-flex">
                <div className="product_add">
                  <span className="add_product_text">
                    <span onClick={() => handleDecreaseQty(index)}>-</span>{" "}
                    {item?.selectedQty}{" "}
                    <span onClick={() => handleIncreaseQty(index)}>+</span>
                  </span>
                </div>
                <div className="bold-price ms-3">
                  RS:{" "}
                  {`${
                    item?.retailPrice && item?.selectedQty
                      ? Number(item.retailPrice) * Number(item.selectedQty)
                      : "00"
                  }`}{" "}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {products?.length > 0 && (
        <div>
          {" "}
          <div className="price_container">
            <div>
              <p className="SUbtotal_head">Subtotal</p>
            </div>
            <div>
              <p className="Subtotal_price">RS: {subtotal}</p>
            </div>
          </div>{" "}
          <div className="d-flex gap-3">
            <div className="w-50">
              <button className="view_cart_btn" onClick={handleViewCart}>
                View Cart
              </button>{" "}
            </div>
            <div className="w-50">
              <button
                onClick={nextChecout}
                className="view_cart_btn chech_out_btn"
              >
                Check Out
              </button>
            </div>
          </div>{" "}
        </div>
      )}
    </>
  );
};

export default ProductTableModalContent;
