import React from 'react'
import "./style.css"
const EmptyCart = () => {
  return (
    <div className='mt-5 mb-5 p-5'>
      <div className="hdt-contents">
        <div>
          <h3 className='emty_cart_head '>
            Your cart is empty 
          </h3>
          <p className='text_emty'>
            You may check out all the available products and buy some in the
            shop
          </p>
          <a
            href="/"
            className="return_btn gap-2 hdt-btn hdt-oh hdt-inline-flex hdt-align-center hdt-justify-center hdt-relative hdt-btn-solid hdt-font-normal"
          >
            <span>Return to shop</span>
            <svg
              className="hdt-icon hdt-icon-2"
              xmlns="http://www.w3.org/2000/svg"
              width={12}
              height={12}
              viewBox="0 0 64 64"
              fill="currentColor"
            >
              <path d="M6.89,64,0,57.11,47.26,9.85H4.92V0H64V59.08H54.15V16.74Z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

export default EmptyCart