import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import cart from "../../../../asesst/cart.png";
import RightSideModal from "../RigthSIteModal";
import ProductTableModalContent from "../RigthSIteModal/Tablemodal";
import { CartIcon } from "../../../../component/icon";
import { ContentContext } from "../../../../store";
import Placeholder from "../../../../asesst/placeholder.jpeg";
function OpenCart({ domain, initialProducts }) {
  const { isModalOpen, setIsModalOpen, setselectedItems, selectedItems } =
    useContext(ContentContext);
  useEffect(() => {
    const fetchStoredProducts = async () => {
      const storedProducts = localStorage.getItem(`${domain}-carts`);
      let products = storedProducts ? JSON.parse(storedProducts) : [];

      setselectedItems(products);
    };

    fetchStoredProducts();
  }, []);

  return (
    <div className="cart_postion" onClick={() => setIsModalOpen(true)}>
      <div className="position-relative">
        {" "}
        {/* <div className="btn-count">20</div> */}
      </div>

      <button className="open-modal-button">
        <CartIcon />
      </button>

      <RightSideModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
         
        }}
      >
        <ProductTableModalContent initialProducts={initialProducts} /> 
      </RightSideModal>
    </div>
  );
}

export default OpenCart;
