import React, { useContext } from "react";
import { ContentContext } from "../../../../store";

const RightNav = ({ domain }) => {
  const [cartCount, setCartCount] = React.useState(0);
  const { selectedItems} = useContext(ContentContext);
  const updateCartCount = () => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    const products = storedProducts ? JSON.parse(storedProducts) : [];
    setCartCount(products.length);
  };

  React.useEffect(() => {
    updateCartCount(); // Initial update
    const handleStorageChange = (event) => {
      if (event.storageArea === localStorage) {
        updateCartCount(); // Update cart count on storage change
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window?.removeEventListener("storage", handleStorageChange);
    };
  }, [selectedItems]); // Empty dependency array ensures this runs once on mount

  return (
    <div className="icon_section ">
      {/* <div className="nav-icon d-large-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <g fill="none" fill-rule="evenodd">
            <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
            <path
              fill="currentColor"
              d="M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2M4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0"
            />
          </g>
        </svg>
      </div> */}
      {/* <div className="nav-icon d-large-block">
        <div className="count-box "> 2</div>{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3"
          />
        </svg>
      </div> */}
      <a className="nav-icon" href="/cart">
        <div className="count-box "> {cartCount}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            fill-rule="evenodd"
            d="M5 4h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1M2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3zm10 7c-2.761 0-5-2.686-5-6h2c0 2.566 1.67 4 3 4s3-1.434 3-4h2c0 3.314-2.239 6-5 6"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
  );
};

export default RightNav;
